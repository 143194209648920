import React, { Component } from 'react';
import authStore from '../stores/AuthStore';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { UserRoleEnum } from '../stores/TeamStore';
/**
 * props: roles: string or []
 * 比如用户有【1，2】权限，某个组件需要【2，3】其中之一的权限(即roles=[2,3]) 即可看，则用户可看
 * @class CheckAccess
 * @extends {Component}
 */

@observer
class CheckAccess extends Component {
  // async componentDidMount() {}

  render() {
    if (ExistExcludeRoles(this.props.excludeRoles)) {
      return null;
    }

    if (checkRoleAccess(this.props.roles)) {
      return this.props.children;
    }

    return null;
  }
}

export function checkRoleAccess(roles) {
  const user = authStore.loggedUser;

  if (!user || !user.user_role) {
    return false;
  }

  if (!roles || roles.length == 0) {
    // 不需要role
    return true;
  }

  if (user.isAdmin) {
    //是admin user
    // return true;
  }

  if (user.user_role) {
    const user_role = _.isString(user.user_role)
      ? JSON.parse(user.user_role)
      : user.user_role;

    // if (user_role.indexOf(UserRoleEnum.admin) >= 0) {
    //   //是admin user
    //   return true;
    // }

    const matchRole = _.find(user_role, function(ur) {
      return roles.indexOf(ur) >= 0;
    });

    if (matchRole) {
      return true;
    }
  }

  return false;
}

/**
 * 是否有某些需要排除的roles
 *
 * @export
 * @param {*} excludeRoles
 * @returns
 */
export function ExistExcludeRoles(excludeRoles) {
  const user = authStore.loggedUser;

  if (!user || !user.user_role) {
    return false;
  }

  if (!excludeRoles || excludeRoles.length == 0) {
    // 不需要关心，因为没有
    return false;
  }

  if (user.user_role) {
    const user_role = _.isString(user.user_role)
      ? JSON.parse(user.user_role)
      : user.user_role;

    const matchRole = _.find(user_role, function(ur) {
      return excludeRoles.indexOf(ur) >= 0;
    });

    if (matchRole) {
      return true;
    }
  }

  return false;
}

export default CheckAccess;
