import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import * as httpService from '../services/HttpService';
import { apiConfig } from '../settings/index';
import _ from 'lodash';
import authStore from '../stores/AuthStore';

class TeamStore {
  @observable teamList = [];
  @observable currentEntity = null;

  @action
  async fetchList(param = { limit: 10, page: 1 }) {
    console.log('fetchList: ');

    const filters = ['user_active||eq||1'];
    if (!authStore.loggedUser.isAdmin) {
      filters.push(`customer_id||eq||${authStore.loggedUser.customer_id}`);
    }

    try {
      const { data } = await axios.get(apiConfig.customerUserEndPoint, {
        params: {
          ...param,
          filter: filters
          // join: ['customer', 'addressZone']
        }
      });
      runInAction(() => {
        this.teamList = data.data || [];
      });

      return data;
    } catch (error) {
      console.log('TCL: TeamStore -> fetchList -> error', error);
    }
  }

  @action
  async saveRecord(record) {
    if (!record.id) {
      //create
      const { data } = await axios.post(apiConfig.customerUserEndPoint, record);
      // this.teamList.push(data);
    } else {
      const { data } = await axios.patch(
        apiConfig.customerUserEndPoint + `/${record.id}`,
        record
      );

      console.log('TCL: saveRecord -> data', data);
    }

    runInAction(() => {
      this.teamList = this.teamList.slice() || [];
    });

    // const { data } = await httpService.SaveTeamRecord(record);

    // runInAction(() => {
    //   if (record.id) {
    //     if (this.currentRecord) {
    //       this.currentRecord = Object.assign(this.currentRecord, data);
    //     }
    //   } else {
    //     this.teamList.push(data);
    //   }
    // });
  }

  @action
  async deleteRecord(record) {
    record.user_active = '0';
    try {
      await axios.patch(
        apiConfig.customerUserEndPoint + `/${record.id}`,
        record
      );
    } catch (error) {
      console.log('delete error', error);
      throw error;
      // S2Notification.alert({
      //   title: "error",
      //   content: "Delete failed. " + error && error.message
      // });
    }
  }
}

// 已选中用户的roles
// const UserRoles = {
//   confirm_order: 1,
//   view_price: 1,
//   topup_wallet: 1,
//   manage_user: 1,
//   update_account: 1,
//   manage_address: 1
// };

const UserRoleEnum = {
  create_order: 1,
  confirm_order: 2,
  view_price: 3,
  topup_wallet: 4,
  manage_user: 5,
  update_account: 6,
  manage_address: 7,

  admin: 9001, // 该role仅针对admin, super admin,
  manager: 9002, // 2 in db
  csAdmin: 9003, // 3 in db
  operationManager: 9004 //4 in db
};

const UserRoles = [
  {
    value: 1,
    role: 'create_order',
    label: 'Create Order'
  },
  {
    value: 2,
    role: 'confirm_order',
    label: 'Confirm Order'
  },
  {
    value: 3,
    role: 'view_price',
    label: 'View Price'
  },
  {
    value: 4,
    role: 'topup_wallet',
    label: 'Top Up'
  },
  {
    value: 5,
    role: 'manage_user',
    label: 'Manage User'
  },
  {
    value: 6,
    role: 'update_account',
    label: 'Update Account'
  },
  {
    value: 7,
    role: 'manage_address',
    label: 'Manage Address'
  },
  {
    value: 9001,
    role: 'admin',
    label: 'Super Admin'
  },
  {
    value: 9002,
    role: 'manager',
    label: 'Manager'
  },
  {
    value: 9003,
    role: 'csAdmin',
    label: 'CS Admin'
  },
  {
    value: 9004,
    role: 'operationManager',
    label: 'Operation Manager'
  }
];

export function ConvertToRole(roleArr = []) {
  if (!roleArr || roleArr.length == 0) {
    return [];
  }

  return _.filter(UserRoles, (item) => {
    return roleArr.indexOf(item.value) >= 0;
  });
}

export default new TeamStore();

export { TeamStore, UserRoles, UserRoleEnum };
