import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import authStore from '../../stores/AuthStore';
import { observer } from 'mobx-react';

const { logout } = authAction;

@observer
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { loggedUser } = authStore;

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="topbar.help" />
        </a>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <div>
        <div className="isoImgWrapper">
          {/* <Popover
            content={content}
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            arrowPointAtCenter={true}
            placement="bottomLeft"
          > */}
            <div className="name">
              {authStore.loggedUser && authStore.loggedUser.user_name}
            </div>
          {/* </Popover> */}
          {/* {loggedUser && loggedUser.isAdmin ? null : (
            <div className="balance">SGD {authStore.userBalance}</div>
          )} */}
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarUser);
