//https://www.iconfont.cn/collections/detail?spm=a313x.7781069.0.da5a778a4&cid=1832

//NOTE: leftIcon= ion-加上述链接的icon name
// NOTE: roles: 需要哪些角色才能显示
// NOTE: hideForRoles: 哪些角色不能显示（先判断这个，再判断roles）

import { UserRoleEnum } from '../../stores/TeamStore';
import authStore from '../../stores/AuthStore';

export default function getOptions() {
  return [
    {
      key: '',
      label: 'sidebar.dashboard',
      leftIcon: 'ion-arrow-graph-up-right'
    },
    {
      key: 'order/new',
      label: 'sidebar.newOrder',
      leftIcon: 'ion-edit',
      roles: [UserRoleEnum.create_order],
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'order',
      label: 'sidebar.myOrders',
      leftIcon: 'ion-ios-list',
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'admin-order',
      label: 'sidebar.Orders',
      leftIcon: 'ion-ios-list',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'cart',
      label: 'sidebar.myCart',
      leftIcon: 'ion-android-cart',
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'wallet',
      label: authStore.loggedUser && authStore.loggedUser.isAdmin ? 'sidebar.walletActivity' : 'sidebar.myWallet',
      leftIcon: 'ion-social-usd',
      roles: authStore.loggedUser && authStore.loggedUser.isAdmin ? undefined : [UserRoleEnum.topup_wallet]
    },
    {
      key: 'my-rewards-points',
      label: 'sidebar.myRewardsPoints',
      leftIcon: 'ion-cube',
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },

    {
      key: 'exchange-item-manage',
      label: 'sidebar.exchange-item-manage',
      leftIcon: 'ion-bag',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },

    {
      key: 'exchange-records',
      label: 'sidebar.exchangeRecords',
      leftIcon: 'ion-cube',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },

    {
      key: 'address',
      label: 'sidebar.myDetail',
      leftIcon: 'ion-ios-information-outline',
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'team',
      label: 'sidebar.myTeam',
      leftIcon: 'ion-android-people',
      roles: [UserRoleEnum.manage_user],
      hideForRoles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },

    {
      key: 'report',
      label: 'sidebar.report',
      leftIcon: 'ion-ios-pulse-strong',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager],
      children: [
        {
          key: 'edging',
          label: 'sidebar.report.edging'
        },
        {
          key: 'delivery',
          label: 'sidebar.report.delivery'
        },
        {
          key: 'product_statistics',
          label: 'sidebar.report.product_statistics'
        },
        {
          key: 'order_statistics_by_vip',
          label: 'sidebar.report.order_statistics_by_vip'
        },
        {
          key: 'order_statistics_by_customer_with_quantity_new',
          label: 'sidebar.report.order_statistics_by_customer_with_quantity_new'
        },
        // {
        //   key: 'order_statistics_by_customer',
        //   label: 'sidebar.report.order_statistics_by_customer'
        // },
        {
          key: 'order_monthly_sales',
          label: 'sidebar.report.order_monthly_sales'
        }
        // {
        //   key: 'order_statistics_by_customer_with_quantity',
        //   label: 'sidebar.report.order_statistics_by_customer_with_quantity'
        // }
      ]
    },
    {
      key: 'customer-manage',
      label: 'sidebar.customer-manage',
      leftIcon: 'ion-ios-list',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'customer-group-manage',
      label: 'sidebar.customer-group-manage',
      leftIcon: 'ion-person-stalker',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'product-category-manage',
      label: 'sidebar.product-category-manage',
      leftIcon: 'ion-person-stalker',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },
    {
      key: 'product-manage',
      label: 'sidebar.product-manage',
      leftIcon: 'ion-ios-list',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    },

    {
      key: 'system-setting',
      label: 'sidebar.system-setting',
      leftIcon: 'ion-android-settings',
      roles: [UserRoleEnum.admin, UserRoleEnum.manager, UserRoleEnum.csAdmin, UserRoleEnum.operationManager]
    }
    // {
    //   key: 'signin',
    //   label: 'sidebar.logout',
    //   leftIcon: 'ion-log-out',
    //   withoutDashboard: true
    // }
  ];
}
// export default options;
