const serverUrl = process.env.NODE_ENV == 'production' ? '' : 'http://127.0.0.1:9000/';
const apiUrl = process.env.NODE_ENV == 'production' ? 'api/' : 'http://127.0.0.1:9000/api/';

export default {
  apiUrl: apiUrl
};

const siteConfig = {
  siteName: 'VOL Online Portal',
  siteIcon: 'ion-flash',
  footerText: 'VOL Online Portal ©2019'
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';

const jwtConfig = {
  fetchUrl: apiUrl, //'/api/',
  secretKey: 'secretKey'
};

let projectConfig = {
  server_url: serverUrl,
  server_api_url: apiUrl,
  displayTimeFormat: 'hh:mm a',
  yearFormat: 'YYYY',
  dateFormat: 'DD/MM/YYYY',
  fullDateTimeFormat: 'DD/MM/YYYY HH:mm',

  databaseDateTimeFormat: 'YYYY-MM-DD HH:mm:ss', // 这个仅只数据库存储时间字符串格式，用来筛选比较
  acceptFileType: '.doc',
  acceptImageType: 'image/*',
  lowerWalletAlertAmount: 50,

  debug: false
};

let paypalConfig = {
  notifyUrl:
    process.env.NODE_ENV == 'production'
      ? 'https://order.koun.com.sg/api/paypalNotify'
      : 'https://4b0ae9eb.ngrok.io/api/paypalNotify',
  successReturn: 'https://order.koun.com.sg/#/dashboard/wallet',
  cancelReturn: 'https://order.koun.com.sg/#/dashboard/wallet',
  adminPaypalAccount: 'payment@koun.com.sg' // 'haisapan@hotmail.com'
};

// projectConfig.fullDateTimeFormat =
//   projectConfig.dateFormat + ' ' + projectConfig.displayTimeFormat;

const apiConfig = {
  apiUrl: apiUrl,
  orderEndPoint: apiUrl + 'op-order',
  orderRemarkEndPoint: apiUrl + 'op-order-remark',
  walletEndPoint: apiUrl + 'wallet-activity',
  customerAddressEndPoint: apiUrl + 'customer-address',
  customerGroupEndPoint: apiUrl + 'customer-group',
  teamEndPoint: apiUrl + 'team',
  categoryEndPoint: apiUrl + 'category',
  customerEndPoint: apiUrl + 'customer',
  customerUserEndPoint: apiUrl + 'customer-user',
  productEndPoint: apiUrl + 'product',
  productGroupEndPoint: apiUrl + 'product-group',
  productInventoryEndPoint: apiUrl + 'product-inventory',
  supplierEndPoint: apiUrl + 'supplier',
  userEndPoint: apiUrl + 'user',
  zoneEndPoint: apiUrl + 'zone',
  settingEndPoint: apiUrl + 'setting',
  exchangeItemEndPoint: apiUrl + 'exchange-item',
  exchangeRecordEndPoint: apiUrl + 'exchange-record',
  uploadEndPoint: serverUrl + 'upload',
  pointEndPoint: apiUrl + 'point-activity'
};

export { siteConfig, language, themeConfig, jwtConfig, apiConfig, projectConfig, paypalConfig };
