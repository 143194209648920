import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import { checkIsTouchScreen } from './helpers/utility';


const isTouchScreen = checkIsTouchScreen();
if(isTouchScreen){
  // document.body.style.width='1200px'
}

ReactDOM.render(<DashApp />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
