import notification from './feedback/notification';

const createNotification = (type, message, description, duration = 5) => {
  notification[type]({
    message,
    description,
    duration
  });
};
export default createNotification;
