import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { apiConfig } from '../settings/index';
import authHelper from '../helpers/authHelper';
import { setToken, clearToken, getToken } from '../helpers/utility';
/**
 * ç™»å½•æ ¡éªŒ
 *
 * @class AuthStore
 */
class AuthStore {
  @observable loggedUser = null;

  @observable isAdmin = false;

  @computed get userBalance() {
    if (!this.loggedUser) {
      return 0;
    }
    console.log(
      'TCL: AuthStore -> @computed get user Balance -> this.loggedUser',
      this.loggedUser
    );

    const userBalance =
      (this.loggedUser &&
        this.loggedUser.customer &&
        this.loggedUser.customer.customer_credit) ||
      '0';

    return parseFloat(userBalance).toFixed(2);
  }

  @computed get userPoint() {
    if (!this.loggedUser) {
      return 0;
    }

    const userPoint =
      (this.loggedUser &&
        this.loggedUser.customer &&
        this.loggedUser.customer.customer_point) ||
      '0';

    return parseFloat(userPoint).toFixed(2);
  }

  @action
  setLoggedUser(payload) {
    if (!payload) {
      return;
    }
    this.loggedUser = payload.user;
    this.token = payload.token;

    axios.defaults.headers.common['Authorization'] = this.token;

    // setTimeout(() => {
    //   this.getUserInfoById();
    // }, 2000);
  }

  @action
  async getUserInfoById() {
    console.log('fetchList: ');

    try {
      const { data } = await axios.post(apiConfig.apiUrl + 'token', {
        token: this.token
      });
      runInAction(() => {
        const payload = authHelper.checkExpirity(data.token);
        console.log('TCL: AuthStore -> getUserInfoById -> payload', payload);
        setToken(payload.token);
        this.setLoggedUser(payload);
        // this.loggedUser = data || this.loggedUser;
      });
    } catch (error) {
      console.log('TCL: TeamStore -> fetchList -> error', error);
    }
  }
}

export default new AuthStore();

export { AuthStore };
