import axios from 'axios';

import { apiConfig } from '../settings';

//#region Address Api

export function GetAddressList(filters) {
  return axios({
    url: apiConfig.addressEndPoint,
    method: 'get'
  });
}

export function GetAddress(id) {
  return axios({
    url: apiConfig.addressEndPoint + `/${id}`,
    method: 'get'
  });
}

export function SaveAddressRecord(data) {
  return axios({
    url: apiConfig.addressEndPoint + (data.id ? `/${data.id}` : ''),
    method: data.id ? 'put' : 'post',
    data
  });
}

export function DeleteAddressRecord(data) {
  return axios({
    url: apiConfig.addressEndPoint + '/' + data.id,
    method: 'delete'
  });
}

// endregion Address Api



//#region Team Api

export function GetTeamList(filters) {
  return axios({
    url: apiConfig.teamEndPoint,
    method: 'get'
  });
}

export function GetTeam(id) {
  return axios({
    url: apiConfig.teamEndPoint + `/${id}`,
    method: 'get'
  });
}

export function SaveTeamRecord(data) {
  return axios({
    url: apiConfig.teamEndPoint + (data.id ? `/${data.id}` : ''),
    method: data.id ? 'put' : 'post',
    data
  });
}

export function DeleteTeamRecord(data) {
  return axios({
    url: apiConfig.teamEndPoint + '/' + data.id,
    method: 'delete'
  });
}

// endregion Team Api