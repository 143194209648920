import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  // {
  //   path: '',
  //   component: asyncComponent(() => import('../dashboard')),
  // },
  {
    path: '',
    component: asyncComponent(() => import('../Widgets/index.js'))
  },

  {
    path: 'wallet-transaction/:transactionId',
    component: asyncComponent(() => import('../Wallet/singleInvoice'))
  },
  {
    path: 'wallet',
    component: asyncComponent(() => import('../Wallet'))
  },
  {
    path: 'my-rewards-points',
    component: asyncComponent(() => import('../RewardsPoints'))
  },
  {
    path: 'exchange-records',
    component: asyncComponent(() => import('../RewardsPoints/admin'))
  },
  {
    path: 'top-up',
    component: asyncComponent(() => import('../Wallet/topup'))
  },

  {
    path: 'address',
    component: asyncComponent(() => import('../CustomerAddress'))
  },
  {
    path: 'address/edit/:id?',
    component: asyncComponent(() => import('../CustomerAddress/editAddress'))
  },
  {
    path: 'address/view/:id',
    component: asyncComponent(() => import('../CustomerAddress/viewAddress'))
  },

  {
    path: 'team',
    component: asyncComponent(() => import('../Team'))
  },
  {
    path: 'team/edit/:id?',
    component: asyncComponent(() => import('../Team/edit'))
  },
  {
    path: 'team/view/:id',
    component: asyncComponent(() => import('../Team/view'))
  },
  {
    path: 'order',
    component: asyncComponent(() => import('../Order'))
  },
  {
    path: 'admin-order',
    component: asyncComponent(() => import('../Order/admin'))
  },
  {
    path: 'cart',
    component: asyncComponent(() => import('../Order/myCart'))
  },
  {
    path: 'order/new',
    component: asyncComponent(() => import('../Order/newOrderStepFirst'))
  },
  {
    path: 'order/new-rx',
    component: asyncComponent(() => import('../Order/edit/rxForm.free'))
  },
  {
    path: 'order/new-rx-full',
    component: asyncComponent(() => import('../Order/edit/rxForm.full'))
  },

  // {
  //   path: 'order/new-rx-special',
  //   component: asyncComponent(() => import('../Order/edit/rxForm.special'))
  // },
  {
    path: 'order/new-stock',
    component: asyncComponent(() => import('../Order/edit/stockForm'))
  },
  {
    path: 'order/edit/:id',
    component: asyncComponent(() => import('../Order/edit/index'))
  },
  {
    path: 'order/new-stock-progressive',
    component: asyncComponent(() => import('../Order/edit/stockProgressiveForm'))
  },

  {
    path: 'order/view/:id',
    component: asyncComponent(() => import('../Order/view/index'))
  },

  {
    path: 'edging',
    component: asyncComponent(() => import('../Report/edging'))
  },
  {
    path: 'delivery',
    component: asyncComponent(() => import('../Report/delivery'))
  },

  {
    path: 'product_statistics',
    component: asyncComponent(() => import('../Report/product_statistics'))
  },
  {
    path: 'order_statistics_by_vip',
    component: asyncComponent(() => import('../Report/order_statistics_by_vip_with_quantity'))
  },
  {
    path: 'order_statistics_by_customer',
    component: asyncComponent(() => import('../Report/order_statistics_by_customer_with_quantity'))
  },
  {
    path: 'order_statistics_by_customer_with_quantity',
    component: asyncComponent(() => import('../Report/order_statistics_by_customer_with_quantity'))
  },
  {
    path: 'order_statistics_by_customer_with_quantity_new',
    component: asyncComponent(() => import('../Report/order_statistics_by_customer_with_quantity_new'))
  },
  {
    path: 'order_monthly_sales',
    component: asyncComponent(() => import('../Report/order_monthly_sales'))
  },

  {
    path: 'customer-manage',
    component: asyncComponent(() => import('../CustomerManage/index'))
  },
  {
    path: 'customer-manage/edit/:id?',
    component: asyncComponent(() => import('../CustomerManage/edit'))
  },
  {
    path: 'customer-group-manage',
    component: asyncComponent(() => import('../CustomerGroupManage/index'))
  },

  {
    path: 'customer-group-manage/edit/:id?',
    component: asyncComponent(() => import('../CustomerGroupManage/edit'))
  },
  {
    path: 'system-setting',
    component: asyncComponent(() => import('../MasterSetting'))
  },
  {
    path: 'exchange-item-manage',
    component: asyncComponent(() => import('../ExchangeItem'))
  },
  {
    path: 'exchange-claim',
    component: asyncComponent(() => import('../RewardsPoints/claim'))
  },
  {
    path: 'product-manage/edit/:id?',
    component: asyncComponent(() => import('../ProductManage/edit'))
  },
  {
    path: 'product-manage',
    component: asyncComponent(() => import('../ProductManage'))
  },
  {
    path: 'product-category-manage',
    component: asyncComponent(() => import('../ProductCategoryManage/index'))
  }
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
